.app {
  text-align: center;
  max-width: 500px;
  margin: 100px auto;
}
.input-group {
  display: flex;
  flex-direction: column;
}
.input-group input {
  padding: 15px;
}

.btn {
  background-color: rgb(77, 35, 31);
  color: #fff;
  margin-top: 20px;
  box-shadow: none;
  border: none;
  padding: 15px 15px;
  cursor: pointer;
  font-size: 25px;
}

.list-container {
  margin-top: 40px;
}
.list-item {
  display: flex;
  align-items: center;
  background-color: rgb(77, 35, 31);
  color: #fff;
  padding: 15px 20px;
  margin-top: 15px;
  cursor: move;
}
.list-item i {
  margin-right: 20px;
  font-size: 30px;
}
h3 {
  font-size: 18px;
  margin: 0;
}
