body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background-color: #eeeeee; */
}

.ant-btn-primary,
.MuiButton-containedPrimary,
.MuiAppBar-colorPrimary,
.CreateUser-avatar-23,
.MuiSwitch-thumb {
  background-color: rgb(77, 35, 31) !important;
  border-color: rgb(77, 35, 31) !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #c0b29e !important;
}

.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

img {
  max-width: 100%;
  height: auto;
}
.preview {
  padding: 16px;
  border: 1px solid rgb(232, 232, 232);
  border-radius: 3px;
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}
.dropzone-container {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.loader-upper {
  position: fixed;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  left: 0;
}
